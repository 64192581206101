import React, { useState, useEffect } from 'react';
import './LandingPage.css';
import logo from './logo.png'; // Importing logo file
import pitchDeck from './pitchdecktrial.mp4'; // Importing pitch deck video file

const LandingPage = () => {
  const [text, setText] = useState('');
  const [index, setIndex] = useState(0);
  const [showText, setShowText] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const message = 'Coming Soon...';

  const scriptURL = 'https://script.google.com/macros/s/AKfycbxGG2ywlNJaGpGs0u2PExz5drb9mE-0s8a7wJJeOOStECiocKyLtQFcXehzBmVFdOwyVQ/exec';

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch(scriptURL, { method: 'POST', body: new FormData(form) })
      .then((response) => {
        console.log('Success!', response);
        form.reset(); // Reset the form fields
      })
      .catch((error) => console.error('Error!', error.message));
  };

  useEffect(() => {
    if (index < message.length) {
      const timeoutId = setTimeout(() => {
        setText(text + message[index]);
        setIndex(index + 1);
      }, 150);
      return () => clearTimeout(timeoutId);
    } else {
      setShowText(true);
      setTimeout(() => {
        setShowContent(true);
      }, 1000); // Delay before showing the rest of the content
    }
  }, [index, text, message]);

  useEffect(() => {
    if (showText && !showContent) {
      const delayId = setTimeout(() => {
        setShowContent(true);
      }, 100); // Additional delay before moving the text
      return () => clearTimeout(delayId);
    }
  }, [showText, showContent]);

  return (
    <>
      <img src={logo} alt="Logo" className="logo" />
      <div className={`container ${showContent ? 'move-up' : ''}`}>
        <h1 className={`text ${showContent ? 'small' : ''}`}>{text}</h1>
        {showContent && (
          <div className="content">
            <video className="pitch-deck" controls>
              <source src={pitchDeck} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <a href={pitchDeck} download="Pitch_Deck.mp4" className="download-button">
              Download Pitch Deck
            </a>
            <form className="waiting-list-form" name="submit-to-google-sheet" onSubmit={handleSubmit}>
              <h2>Join our waiting list</h2>
              <input type="email" name="Email" placeholder="Enter your email" required />
              <button type="submit">Submit</button>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default LandingPage;
